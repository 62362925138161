/**
 * Application Forms Validation
 *
 * Inspired by Oliver Phillips POC for native validation
 * https://oliverjam.es/blog/better-native-form-validation/
 */

/* global grecaptcha */

function initValidation(form) {
  form.setAttribute('novalidate', '');

  form.addEventListener('submit', () => {
    const allValid = form.checkValidity();
    if (!allValid) {
      event.preventDefault();

      const generalError = document.getElementById( 'generalFormError' );
      if ( generalError ) {
        generalError.textContent = generalError.getAttribute('data-errMissing');
      }
    }
  })

  const fields = Array.from(form.elements);

  fields.forEach(field => {
    const errorId = field.name + 'Error';

    field.setAttribute('aria-invalid', false);
    field.setAttribute('aria-describedBy', errorId);

    var errorBox = document.getElementById( errorId );

    if ( ! errorBox ) {
      errorBox = document.createElement('div')
      errorBox.classList.add('form-error');
      errorBox.setAttribute('id', errorId);
      if ( field.type === 'radio' ) {
        field.insertAdjacentElement('beforebegin', errorBox);
      } else {
        field.insertAdjacentElement('afterend', errorBox);
      }
    }




    field.addEventListener('invalid', () => {
      field.setAttribute('aria-invalid', true);
      const message = getMessage(field);
      errorBox.textContent = message || field.validationMessage;
    })

    field.addEventListener('blur', () => {
      field.checkValidity();
    })

    field.addEventListener('input', () => {
      const valid = field.checkValidity();
      if (valid) {
        field.setAttribute('aria-invalid', false);
        errorBox.textContent = '';

        const generalError = document.getElementById( 'generalFormError' );
        if ( generalError.textContent !== '' ) {
          if ( form.checkValidity() ) {
            const generalError = document.getElementById( 'generalFormError' );
            generalError.textContent = '';
          }
        }
      }
    });
  })
}

function getMessage(field) {
  const validity = field.validity;
  if (field.hasAttribute( 'data-errMissing' ) && field.hasAttribute( 'data-errInvalid' ) ) {
    if (validity.valueMissing) return field.getAttribute( 'data-errMissing' );
    if (validity.typeMismatch) return field.getAttribute( 'data-errInvalid' );
  } else {
    if (validity.valueMissing) return `Please enter your ${field.name || field.id}`;
    if (validity.typeMismatch) return `Please enter a valid ${field.type}`;
  }

}

function onloadCallback() {
  try {
    grecaptcha.ready(function () {
      grecaptcha.execute('6Ld6jZgaAAAAACX8wfCVEIsvH4MbNuT582Qi6Nyd', { action: 'submit' }).then(function (token) {
          var recaptchaResponse = document.getElementById('recaptchaResponse');
          recaptchaResponse.value = token;
      });
    });
  }
  catch (e) {
    console.log('Error loading recaptcha. ' + e);
  }

}


const form = document.querySelector('form');
if ( form ) {
  window.onloadCallback = onloadCallback;
  initValidation(form);

}

